export const carouselInicio = [
    {
        "imagen" : "https://jefaturadegobierno.cdmx.gob.mx/storage/app/uploads/public/66c/403/632/66c403632ed6d032408101.jpeg"
    },
    {
        "imagen" : "https://pbs.twimg.com/media/F3WI_7ZXEAEks3X?format=jpg&name=large"
    },
    {
        "imagen" : "https://elinformante.mx/wp-content/uploads/2024/08/5cdmx-2.jpg"
    },
    {
        "imagen" : "https://visorurbano.hidalgo.gob.mx/coemere/img/portada1.jpeg"
    },
    
]
export const Disciplina = [
    {
        "titulo" : "hola disciplina",
        "subtitulo" : [
            {
                "informacionTitulo" : "este es el titulo disciplina financiera"
            }
        ]
    },
    {
        "titulo" : "Disciplina Financiera2"
    }
];
// src/json/indicadores.js
export const indicadoresData = [
    { numero: 104, descripcion: 'Programas Anuales de Mejora Regulatoria desarrollados por Dependencias, Organismos Descentralizados y Municipios' },
    { numero: 27, descripcion: 'Modelos Simplificados implementados en diversos municipios de Hidalgo y reconocidos por la Comisión Nacional de Mejora Regulatoria' },
    { numero: 15, descripcion: 'Posición a nivel Nacional en el Indicador de Avance de la Estrategia Nacional de Mejora Regulatoria 2022' },
    // { numero: 84, descripcion: 'Comisiones municipales de mejora regulatoria instaladas' },
    // { numero: 13, descripcion: 'Módulos del Sistemas de Apertura Rápida de Empresas (SARE)' },
    // { numero: 4, descripcion: 'Sistemas para la Emisión de Licencia de Construcción' },
    // { numero: 14, descripcion: 'Modelos para el despliegue de infraestructura pasiva de telecomunicaciones' },
    // { numero: 9, descripcion: 'Municipio reconocido por CONAMER (PROREFORMA en telecomunicaciones)' }
  ];
  
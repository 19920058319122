export const licitaciones = [
    {
        "anio" : "2019",
        "licitacionesLista" : [
            {
                "nombre" : "licitacion 1",
                "contenidoLicitacion" : [
                    {
                        "nombre" : "Bases",
                        "url" : "https://www.google.com"
                    },
                    {
                        "nombre" : "Junta de Aclaraciones",
                        "url" : "https://www.google.com"
                    },
                    {
                        "nombre" : "Presentación y Apertura de propuestas",
                        "url" : "https://www.google.com"
                    },
                    {
                        "nombre" : "Fallo",
                        "url" : "https://www.google.com"
                    }
                ]
            }
        ]
    },
    {
        "anio" : "2020",
        "licitacionesLista" : [
            {
                "nombre" : "licitacion 2",
                "contenidoLicitacion" : [
                    {
                        "nombre" : "Bases",
                        "url" : ""
                    },
                    {
                        "nombre" : "Junta de Aclaraciones",
                        "url" : "https://www.google.com"
                    },
                    {
                        "nombre" : "Presentación y Apertura de propuestas",
                        "url" : "https://www.google.com"
                    },
                    {
                        "nombre" : "Fallo",
                        "url" : ""
                    }
                ]
            }
        ]
    }
]
import React, { useEffect, useState, useRef } from 'react';
import './indicadores.css';
import { indicadoresData } from '../../json/indicadores';

const Indicadores = () => {
  const [counts, setCounts] = useState(indicadoresData.map(() => 0));
  const [hasStarted, setHasStarted] = useState(false);
  const indicadoresRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setHasStarted(true);
          indicadoresRef.current.classList.add('visible'); // Añade la clase cuando es visible
          observer.disconnect();
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1
      }
    );

    if (indicadoresRef.current) {
      observer.observe(indicadoresRef.current);
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    if (hasStarted) {
      const interval = setInterval(() => {
        setCounts(prevCounts =>
          prevCounts.map((count, index) => {
            if (count < indicadoresData[index].numero) {
              return count + Math.ceil(indicadoresData[index].numero / 100); // Incremento más lento
            }
            return indicadoresData[index].numero;
          })
        );
      }, 45); // Intervalo más lento

      return () => clearInterval(interval);
    }
  }, [hasStarted]);

  return (
    <div className="indicadores-container" ref={indicadoresRef}>
      <h2 className="title">Indicadores de Desempeño</h2>
      <hr className='hr-gob' />
      <div className="indicadores-linea-horizontal">
        {indicadoresData.map((indicador, index) => (
          <div key={index} className="indicador-punto">
            <div className="numero">{counts[index]}</div>
            <div className="descripcion">{indicador.descripcion}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Indicadores;

export const rubros = [
		{
			
			"nombre": "Registro Único de Trámites y Servicios (RUTS)",
			"icono": "Estructura.png",
			"texto":"En RUTS tienes acceso a los trámites más solicitados de la Administración Pública, y a toda la información que como usuario necesitas para realizarlos.",
			"url": "https://ruts.hidalgo.gob.mx/",
			"formatos": [
				{
					"nombre": "Marco Normativo",
					"xlsx": "f1",
					// "urlXlsx" : "https://www.google.com",
					"html": "f1",
					// "urlHtml" : "https://www.google.com"
				}
			],
			// "url" : "https://www.google.com"
		}, 
		{
			
			"nombre": "Sistema de Análisis de Impacto Regulatorio (SAIR)",
			"icono":"DeclaracionesPublicas.png",
			"texto":"El SAIR es una herramienta a través de la cual podrás comentar y revisar el contenido de las propuestas de regulatorias de las Dependencias estatales, como lo pueden ser: Leyes, Reglamentos, Avisos, Acuerdos, Lineamientos, Circulares, entre otros",
			"url": "https://hidalgo.azurewebsites.net/",
			"formatos": [
				{
					"nombre": "Facultades",
					"xlsx": "f3",
					"html": "f3"
				}
			]
		},
		{
		"nombre": "Protesta ciudadana",
		"icono":"MecanismosParticipacionCiudadana.png",
		"texto":"Mecanismo ciudadano para obtener solución a una queja o inquietud formal sobre algún trámite y/o servicio",
		"url": "https://protesta.hidalgo.gob.mx/",
		"formatos": [
			{
				"xlsx": "f1",
				// "urlXlsx" : "https://visorurbano.hidalgo.gob.mx/coemere/protesta-ciudadana/",
				"html": "f1",
				// "urlHtml" : "https://www.google.com"
			}
		]
		
	},
	
		{
			
			"nombre": "Normateca (Inventario regulatorio)",
			"icono":"DeclaracionesPublicas.png",
			"texto":"Permite a los ciudadanos consultar de manera sencilla y rápida las regulaciones, normas, y disposiciones legales vigentes en el estado de Hidalgo",
			"url": "https://normateca.hidalgo.gob.mx/",
			"formatos": [
				{
					"nombre": "Facultades",
					"xlsx": "f3",
					"html": "f3"
				}
			]
		},
		{
			
			"nombre": "Catalogo de inspectores, verificadores y visitantes",
			"icono":"ActasResolucionesComiteTransparencia.png",
			"texto":"Instrumento que forma parte del Registro Estatal de Visitas Domiciliarias",
			"url": "https://ruts.hidalgo.gob.mx/inspectores",
			"formatos": [
				{
					"nombre": "Metas y Objetivos",
					"xlsx": "f4",
					"html": "f4"
				}
			]
			
		},
		{
			
			"nombre": "Lineamientos del Programa Anual de Mejora Regulatoria",
			"icono":"ResultadosAuditorias.png",
			"texto":"Documentos que concentran las mejoras en las Regulaciones y acciones de simplificación de Trámites y Servicios",
			"url": "https://comisionmr.hidalgo.gob.mx/pdf/Lineamientos_PMR_2020%20veda.pdf",
			"formatos": [
				{
					"nombre": "Indicadores de Interés",
					"xlsx": "f5",
					"html": "f5"
				}
			]
			
		},		
]
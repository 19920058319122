import React, { useEffect, useState } from 'react';
import './FacebookPage.css';

const FacebookPage = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (window.FB) {
      window.FB.XFBML.parse();
      setIsLoading(false);
    } else {
      window.fbAsyncInit = function () {
        window.FB.init({
          xfbml: true,
          version: 'v12.0'
        });
        setIsLoading(false);
      };

      (function (d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s); js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));
    }
  }, []);

  return (
    <div id='facebook_section'>
      <div className='row'>
        <div className='col-12'>
          <div className='notice_section'>
            <div className='notice_title'>
              <h3 className='title'>Nuestras redes sociales</h3>
              <hr className='hr-gob'/>
            </div>
            <div className='notice_content'>
              <div className='col-12'>
                <div id="facebook_page_container">
                  <div id="fb-root"></div>
                  <div className="fb-page"
                    data-href="https://www.facebook.com/profile.php?id=100089700036477"
                    data-tabs="timeline"
                    data-width="500"
                    data-height="500"
                    data-small-header="true"
                    data-adapt-container-width="true"
                    data-hide-cover="false"
                    data-show-facepile="true">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FacebookPage;

import React from 'react';
import './footer.css';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div className='container-footer'>
      <div className='container'>
        <div className='row first-row align-items-center'>
          <div className='col-md-9 col-12'>
            <div className='footer_logo_numbers'>
              <div className='footer_logo'>
                <img src='https://cdn.hidalgo.gob.mx/gobierno/images/logos/logo_gob.png' alt='Logo Gobierno' />
              </div>
              <div className='footer_numbers'>
                <div>
                  <p className="footer_number">911</p>
                  <p>Emergencia</p>
                </div>
                <div>
                  <p className="footer_number">089</p>
                  <p>Denuncia Anónima</p>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-3 col-12'>
            <div className='footer_contacto'>
              <h6><a href='https://gobierno.hidalgo.gob.mx/AvisoPrivacidad' target="_blank" rel="noopener noreferrer">Aviso de privacidad</a></h6>
              <h6>Correo: cemr@hidalgo.gob.mx</h6> 
              <p>Telefono: 771 71 5 66 72</p>              
              <p>Boulevard Circuito la Concepción #3</p>
              <p>Edificio Innovación CITNOVA</p>
              <p>San Agustín Tlaxiaca, Hidalgo, México</p>
              <h6><a href='https://gobierno.hidalgo.gob.mx/AvisoPrivacidad' target="_blank" rel="noopener noreferrer">Aviso de privacidad COEMERE</a></h6>
            </div>
          </div>
        </div>
        <hr className='hr-gob' />
        <div className='row second-row align-items-center'>
          <div className='col-sm-9 col-12 text-left text-sm-left'>
            <p>Copyrights © {currentYear} Todos los derechos reservados por Hidalgo de la Transformación</p>
          </div>
          <div className='col-sm-3 col-12'>
            <div className='footer_sociales'>
              <a href='https://www.facebook.com/profile.php?id=100089700036477' target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-facebook-f"></i></a>
              <a href='https://x.com/coemerehgo' target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-twitter"></i></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;

import React, { useState, useEffect } from 'react';
import './BannerSection.css';

const images = [
  'https://visorurbano.hidalgo.gob.mx/coemere/protesta-ciudadana/img/banner_visor.jpg',
  'https://visorurbano.hidalgo.gob.mx/coemere/protesta-ciudadana/img/banner_ruts2.jpg',
  'https://visorurbano.hidalgo.gob.mx/coemere/protesta-ciudadana/img/banner_protesta.svg',
];

const links = [
  'https://visorurbano.hidalgo.gob.mx/',  // Enlace para la primera imagen
  'https://ruts.hidalgo.gob.mx/',  // Enlace para la segunda imagen
  'https://protesta.hidalgo.gob.mx/',  // Enlace para la tercera imagen
];

const BannerSection = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000); // Cambia la imagen cada 5 segundos

    return () => clearInterval(interval);
  }, [currentIndex]);

  return (
    <div className="banner-section">
      {images.map((image, index) => (
        <a
          key={index}
          href={links[index]}  // Enlace correspondiente a la imagen
          target="_blank"  // Abre el enlace en una nueva pestaña
          rel="noopener noreferrer"  // Buena práctica de seguridad para enlaces externos
          className={`banner-item ${index === currentIndex ? 'active' : 'inactive'}`}
        >
          <img
            src={image}
            alt={`Banner ${index + 1}`}
            style={{ width: '100%', height: 'auto' }}  // Asegura que la imagen se ajuste dentro del contenedor <a>
          />
        </a>
      ))}
    </div>
  );
};

export default BannerSection;

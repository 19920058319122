export const rubrosHistoricos = [
    {
        "anio" : "2022",
        "descripcion" : "Disponibilidad de los trimestres anteriores",
        "trimestres" : [
            {
                "trimestre" : "Primer Trimestre",
                "url" : "www.google.com"
            },
            {
                "trimestre" : "Segundo Trimestre",
                "url" : "www.google.com"
            },
            {
                "trimestre" : "Tercer Trimestre",
                "url" : "www.google.com"
            },
            {
                "trimestre" : "Cuarto Trimestre",
                "url" : "www.google.com"
            }
        ]
    },
    {
        "anio" : "2015 - 2017",
        "descripcion" : "Ejercicios Anteriores",
        "trimestres" : [
            {
                "trimestre" : "Plataforma Nacional",
                "url" : "www.google.com"
            }
        ]
    },
    {
        "anio" : "Información Histórica 21 Rubros",
        "descripcion" : "Ejercicios Anteriores",
        "trimestres" : [
            {
                "trimestre" : "Descargar",
                "url" : "www.google.com"
            }
        ]
    }
]
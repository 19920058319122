export const estadoActividades = [
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ]
    }
];

export const disciplinaFinanciera = [
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ]
    }
];

export const informacionPresupuestaria = [
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ]
    }
];

export const ejemplo11 = [
    {
        "anio" : "2030",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ]
    }
];
// src/components/MisionVisionModal/MisionVisionModal.js
import React, { useState, useEffect, useRef } from 'react';
import './misionVisionModal.css';

const MisionVisionModal = ({ content, onClose }) => {
  const [activeTab, setActiveTab] = useState(content); // Estado para la pestaña activa
  const contentRef = useRef(null); // Referencia para el contenido del modal

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTop = 0; // Reinicia el scroll al cambiar de pestaña
    }
  }, [activeTab]);

  const renderContent = () => {
    if (activeTab === 'mision') {
      return 'La Comisión Estatal de Mejora Regulatoria es el órgano desconcentrado de la Unidad de Planeación y Prospectiva del Estado de Hidalgo responsable del seguimiento e implementación de la Ley en materia, cuyo objetivo es promover la eficacia administrativa durante el quehacer gubernamental, simplificando los tiempos y costos originados durante la ejecución de servicios y trámites administrativos e impactando de forma significativa en la reducción de la discrecionalidad durante la emisión de disposiciones administrativas. Somos una Institución eficiente y comprometida con la transformación e inclusión digital, que garantiza y fortalece los principios de transparencia y seguridad jurídica en beneficio de la ciudadanía.';
    } else {
      return 'Ser la institución gubernamental que, a través del desarrollo e impulso de herramientas digitales y tecnológicas, la gestión de datos, y mediante el fortalecimiento de la infraestructura de redes y telecomunicaciones, consolide un andamiaje sólido y moderno bajo un enfoque de gobierno abierto y digital, que facilite la interoperabilidad efectiva y permanente de las áreas que integran el Gobierno del Estado de Hidalgo y fomente el  acceso equitativo a sus trámites y servicios, construyendo una percepción ciudadana cada vez más sólida y positiva.';
    }
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-container" onClick={(e) => e.stopPropagation()}>
        <div className="modal-header">
          <h2>{activeTab === 'mision' ? 'Misión' : 'Visión'}</h2>
          <span className="close-icon" onClick={onClose}>&times;</span>
        </div>
        <div className="modal-tabs">
          <button
            className={`tab-button ${activeTab === 'mision' ? 'active' : ''}`}
            onClick={() => setActiveTab('mision')}
          >
            Misión
          </button>
          <button
            className={`tab-button ${activeTab === 'vision' ? 'active' : ''}`}
            onClick={() => setActiveTab('vision')}
          >
            Visión
          </button>
        </div>
        <div className="modal-content" ref={contentRef}>
          <p>{renderContent()}</p>
        </div>
        <div className="modal-footer">
          <button className="close-button" onClick={onClose}>Cerrar</button>
        </div>
      </div>
    </div>
  );
};

export default MisionVisionModal;

import React, { useState, useEffect, useRef } from 'react';
import { rubros } from '../../json/rubros';
import './rubros.css';

const Rubros = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const rubrosRef = useRef(null);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const handleMouseMove = (event) => {
    setMousePosition({ x: event.clientX, y: event.clientY });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          rubrosRef.current.classList.add('visible'); // Añade la clase visible
          observer.disconnect();
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1
      }
    );

    if (rubrosRef.current) {
      observer.observe(rubrosRef.current);
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, []);

  return (
    <div className='rubros-container' ref={rubrosRef}>
      {}
      <div className='secretaria_funciones'>
        <h3 className='title'>Herramientas de Mejora Regulatoria</h3>
        <hr className='hr-gob' />
      </div>
      
      <div className='rubros-grid'>
        {rubros.map((item, index) => (
          <a
            key={index}
            href={item.url ? item.url : "DetalleRubro/" + item.nombre}
            className='rubro-card'
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
            onMouseMove={handleMouseMove}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className='rubro-icon'>
              <img src={`http://cdn.hidalgo.gob.mx/plantilla_secretarial/Rubros/PNG/${item.icono}`} alt={item.nombre} />
            </div>
            <h3 className='rubro-name'>{item.nombre}</h3>
          </a>
        ))}
      </div>
      
      {hoveredIndex !== null && (
        <div
          className='rubro-tooltip'
          style={{
            left: `${mousePosition.x + 10}px`,
            top: `${mousePosition.y + 10}px`
          }}
        >
          <p>{rubros[hoveredIndex].texto}</p>
        </div>
      )}
    </div>
  );
};

export default Rubros;

export const links = [
    {
        "nombre" : "Plataformas",
        // "link" : "/Transparencia",
        "submenu" : [
            {
                "subMenuNombre" : "Visor Urbano",
                "subMenuUrl" : "https://visorurbano.hidalgo.gob.mx/inicio"
            },
            {
                "subMenuNombre" : "SAIR",
                "subMenuUrl" : "https://hidalgo.azurewebsites.net/"
            },
            {
                "subMenuNombre" : "RUTS",
                "subMenuUrl" : "https://ruts.hidalgo.gob.mx/"
            },
            {
                "subMenuNombre" : "Protesta Ciudadana",
                "subMenuUrl" : "https://protesta.hidalgo.gob.mx/"
            },
        ]
    }
]
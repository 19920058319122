import React, { useEffect, useState } from 'react';
import Carousel from '../Carousel/Carousel';
import MisionVisionModal from '../MisionVisionModal/MisionVisionModal';
import FacebookPage from '../FacebookPage/FacebookPage';
import BannerSection from '../BannerSection/BannerSection';
import './inicio.css';
import { titular } from '../../json/titular';
import Rubros from '../Rubros/Rubros';
import Indicadores from '../Indicadores/Indicadores';
import { carouselInicio } from '../../json/carousel';
import LigasInteres from '../LigasInteres/LigasInteres';

const Inicio = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(''); // 'mision' o 'vision'

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleOpenModal = (contentType) => {
    setModalContent(contentType);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <Carousel bannerData={carouselInicio} search={true} name="banner-principal" />
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <div className='secretaria_logo'>
              <div className='secretaria_logo_img' style={{ backgroundImage: `url("https://visorurbano.hidalgo.gob.mx/coemere/img/document.png")` }}>
              </div>
            </div>
          </div>
          <div className='secretaria_name'>
            <h1>COMISIÓN ESTATAL DE MEJORA REGULATORIA</h1>
          </div>
          <div className='secretaria_funciones'>
            <h3 className='title'>¿Qué es la COEMERE?</h3>
            <hr className='hr-gob' />
            <p>Es un órgano administrativo desconcentrado de la Unidad de Planeación y Prospectiva, dotada de autonomía técnica, operativa y de gestión, encargado fundamentalmente de Coordinar e implementar la política en materia de mejora regulatoria, que dé cumplimiento al Plan Estatal de Desarrollo. Su propósito radica en procurar los mayores beneficios para la sociedad con los menores costos posibles, mediante la formulación normativa de reglas e incentivos que estimulen la innovación, la confianza en la economía, la productividad y la eficiencia a favor del crecimiento y bienestar general de la sociedad.</p>
            <div className='btn_organigrama'>
              <button className='styled-button' onClick={() => handleOpenModal('mision')}>Misión</button>
              <button className='styled-button' onClick={() => handleOpenModal('vision')}>Visión</button>
            </div>
          </div>

          {/* Componentes adicionales */}
          <Rubros />
          <Indicadores />
          
        </div>

        <div className='row'>
          <div className='col-12'>
            <div className='notes_title'>
              <h3 className='title'>Titular de la COEMERE</h3>
              <hr className='hr-gob' />
            </div>
            <div className='section_notes'>
              {titular.map((item, index) => (
                <React.Fragment key={index}>
                  <div className='sec_photo'>
                    <div className='img_secretario' style={{ backgroundImage: `url("${item.imagen}")` }}></div>
                  </div>
                  <div className='sec_container'>
                    <div className='sec_name'>
                      <h3>{item.nombre}</h3>
                    </div>
                    <div className='sec_function'>
                      <h4>Función del Titular</h4>
                      <p>{item.funcion}</p>
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>

        <FacebookPage />
        <BannerSection />
        {/* <LigasInteres /> */}
        <div className='row'>
          <div className='col-12'>
            <div className='section_map'>
              <div className='map_name'>
                <h3 className='title'>Ubicación</h3>
                <hr className='hr-gob' />
              </div>
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d17923.409341909777!2d-98.80488388532666!3d20.13160693027187!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d10a8d523d568d%3A0x92d9d067fe2857fc!2sCITNOVA!5e0!3m2!1ses!2smx!4v1725477622726!5m2!1ses!2smx"
                width="100%"
                height="300"
                frameBorder="0"
                style={{ border: 0, marginBottom: 50 }}
                allowFullScreen>
              </iframe>
            </div>
          </div>
        </div>
      </div>

      {/* Modal de Misión y Visión */}
      {modalOpen && <MisionVisionModal content={modalContent} onClose={handleCloseModal} />}
    </>
  );
};

export default Inicio;

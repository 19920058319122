export const Armonizacion = [
    {
        "titulo" : "Información Contable",
        "subtitulo" : [
            {
                "informacionTitulo" : "Estado de Actividades"
            }
        ]
    },
    {
        "titulo" : "Disciplina Financiera"
    },
    {
        "titulo" : "Información Presupuestaria"
    },
    {
        "titulo" : "ejemplo",
        "subtitulo" : [
            {
                "informacionTitulo" : "Ejemplo1.1"
            },
            {
                "informacionTitulo" : "ejemplo1.2"
            }
        ]
    }
];